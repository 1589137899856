import { useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardFilters from './DashboardFilters';
import DashboardDateRanges from './DashboardDateRanges';
import ReportSelect from '../Layouts/ReportSelect';
import { useYOY } from '../../contexts/YOYContext';
import {
    Bars3Icon,
    XMarkIcon,
    FolderArrowDownIcon,
    Bars3BottomLeftIcon,
} from '@heroicons/react/24/solid';

const DashboardNavigation = ({
    theme,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    onDateChange,
    admin,
    handleModalOpen,
    isPdfReady,
    isGenerating,
    setIsAdminNavOpen,
}) => {
    const { showYOY, setShowYOY } = useYOY();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLogoClick = (e) => {
        if (admin && theme === 'mta') {
            e.preventDefault(); // Prevent navigation
            setShowYOY(!showYOY); // Toggle YOY data
        }
    };

    const LogoImage = ({ src, alt, className }) => (
        <img
            className={className}
            src={src}
            alt={alt}
        />
    );

    return (
        <nav className='dashboard-navbar'>
            <div className='dashboard-navbar-wrapper'>
                <div className='flex items-center'>
                    <div className='dashboard-navbar-logo'>
                        {admin ? (
                            <Link
                                to={theme === 'mta' ? '#' : '/admin'}
                                onClick={handleLogoClick}
                            >
                                {theme === 'mta' ? (
                                    <LogoImage
                                        className='h-12 w-auto cursor-pointer'
                                        src='/mta-logo.png'
                                        alt='mta360 logo (click to toggle YOY data)'
                                    />
                                ) : (
                                    <LogoImage
                                        className='h-14 w-auto'
                                        src='/range-pin.png'
                                        alt='Range Marketing logo'
                                    />
                                )}
                            </Link>
                        ) : (
                            theme === 'mta' ? (
                                <LogoImage
                                    className='h-12 w-auto'
                                    src='/mta-logo.png'
                                    alt='mta360 logo'
                                />
                            ) : (
                                <LogoImage
                                    className='h-14 w-auto'
                                    src='/range-pin.png'
                                    alt='Range Marketing logo'
                                />
                            )
                        )}
                    </div>
                    {admin && (
                        <div className='flex items-center space-x-4'>
                            <div
                                className='dashboard-nav-open-admin'
                                role='button'
                                onClick={() => setIsAdminNavOpen(true)}
                            >
                                <Bars3BottomLeftIcon className='h-6 w-6 inline-block mr-2' />
                                <span>Admin</span>
                            </div>
                            {/* Hiding until paul finishes */}
                            {1 == 2 && (
                                <button
                                    onClick={handleModalOpen}
                                    className={`${theme === 'mta' ? 'btn-green' : 'btn-blue'} btn ${!isPdfReady || isGenerating ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={!isPdfReady || isGenerating}
                                >
                                    <FolderArrowDownIcon className='h-5 w-5 inline-block mr-2' />
                                    <span>
                                        {!isPdfReady
                                            ? 'Loading Data...'
                                            : isGenerating
                                                ? 'Generating Report...'
                                                : 'Download Report'}
                                    </span>
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <button
                    className='lg:hidden dashboard-navbar-toggle'
                    onClick={toggleMenu}
                    aria-label='Toggle menu'
                >
                    {isMenuOpen ? (
                        <XMarkIcon className='h-6 w-6' />
                    ) : (
                        <Bars3Icon className='h-6 w-6' />
                    )}
                </button>
                <div className={`dashboard-navbar-content ${isMenuOpen ? 'open' : ''}`}>
                    <div className='dashboard-navbar-right'>
                        <div className='dashboard-navbar-filters'>
                            <ReportSelect />

                            <DashboardFilters
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                onDateChange={onDateChange}
                                initialSelectedRange='last3months'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DashboardDateRanges />
        </nav>
    );
};

export default DashboardNavigation;
