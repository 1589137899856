import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Check if the error is related to the GA4 performance endpoint
    if (error.message && error.message.includes('ga4/performance')) {
      // For GA4 performance errors, we'll just log a warning
      console.warn("GA4 performance data fetch failed:", error.message);
    } else {
      // For all other errors, log as usual
      console.error("Uncaught error:", error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
