import { formatDate, normalizeDate } from './dataProcessing';

/**
 * Creates an empty data structure with current, yearOverYear, and previousPeriod objects.
 * Used as the base structure for storing different types of data snapshots.
 * 
 * @returns {Object} Empty data structure with three period properties
 */
export const createEmptyDataStructure = () => ({
    current: {},
    yearOverYear: {},
    previousPeriod: {},
    customPeriod: {}
});

/**
 * Initializes a data structure for multiple data types.
 * Creates nested objects for each data type across all time periods.
 * 
 * @param {Array<Object>} dataTypes - Array of data type configurations
 * @returns {Object} Initialized data structure with nested objects for each type
 */
export const initializeDataTypeStructure = (dataTypes) => {
    const structure = createEmptyDataStructure();
    dataTypes.forEach(({ key }) => {
        structure.current[key] = {};
        structure.yearOverYear[key] = {};
        structure.previousPeriod[key] = {};
        structure.customPeriod[key] = {};
    });
    return structure;
};

/**
 * Calculates the previous period date range based on current period.
 * Takes the current date range and shifts it back by the same number of days.
 * 
 * @param {Date} startDate - Start date of current period
 * @param {Date} endDate - End date of current period
 * @returns {Object} Previous period start and end dates in formatted string format
 */
export const calculatePreviousPeriod = (startDate, endDate) => {
    // Create dates at start of day in local time
    const normalizedStart = new Date(
        startDate.getFullYear(),
        startDate.getMonth(), 
        startDate.getDate()
    );
    
    const normalizedEnd = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
    );
    
    // Calculate days difference
    const daysDiff = Math.ceil((normalizedEnd - normalizedStart) / (1000 * 60 * 60 * 24));
    
    // Calculate previous end date as day before start
    const previousEndDate = new Date(
        normalizedStart.getFullYear(),
        normalizedStart.getMonth(),
        normalizedStart.getDate()
    );
    
    // Calculate previous start date
    const previousStartDate = new Date(
        previousEndDate.getFullYear(),
        previousEndDate.getMonth(),
        previousEndDate.getDate() - (daysDiff)
    );
    
    return {
        startDate: formatDate(previousStartDate),
        endDate: formatDate(previousEndDate)
    };
};

/**
 * Validates data for a specific data type.
 * Handles special cases for ga4-organic, reviews, and ecommerce data.
 * 
 * @param {Object} data - The data object to validate
 * @param {string} key - The data type key to validate
 * @returns {boolean} Whether the data is valid and non-empty
 */
export const validateDataForType = (data, key) => {
    if (key === 'ga4-organic') {
        return Boolean(
            data.current['ga4'] && 
            Object.keys(data.current['ga4']).length > 0 &&
            Object.values(data.current['ga4']).some(value =>
                value !== null && Object.keys(value).length > 0
            )
        );
    } else if (key === 'reviews') {
        return Boolean(
            data.current[key] &&
            Object.keys(data.current[key]).length > 0 &&
            Object.values(data.current[key]).some(value =>
                value !== null && Object.keys(value).length > 0
            )
        );
    } else if (key === 'ecommerce') {
        return Boolean(
            data.current[key] &&
            Object.keys(data.current[key]).length > 0 &&
            Object.values(data.current[key]).some(value =>
                value?.overview && Object.keys(value.overview).length > 0
            )
        );
    }
    return Boolean(
        data.current[key] &&
        Object.keys(data.current[key]).length > 0 &&
        Object.values(data.current[key]).some(value =>
            value !== null && Object.keys(value).length > 0
        )
    );
};
