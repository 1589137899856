import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useGlobalData } from '../../contexts/GlobalDataContext';
import { prettyDate } from '../../utils/dataProcessing';

const DashboardDateRanges = () => {
    const {
        globalData = {},
        currentStartDate,
        currentEndDate,
        previousStartDate,
        previousEndDate,
        customStartDate,
        customEndDate,
        isYearOverYearDataLoaded,
        isPreviousDataLoaded,
        isCustomPeriodDataLoaded
    } = useGlobalData();

    const DateRange = ({ label, startDate, endDate, icon }) => (
        <div className='report-display-date-range'>
            {icon}
            <span className='date-display-label mr-2'>{label}:</span>
            <span className='text-xs font-semibold'>
                {prettyDate(startDate)} <ChevronRightIcon className='h-3 w-3 inline' /> {prettyDate(endDate)}
            </span>
        </div>
    );

    // Only render if we have current dates
    if (!currentStartDate || !currentEndDate) {
        return null;
    }

    return (
        <div className='dashboard-report-date-display'>
            <div className='date-display-container'>
                <div className='date-display-wrapper'>
                    <DateRange
                        label='Current Period'
                        startDate={currentStartDate}
                        endDate={currentEndDate}
                    />

                    {/* Comparison periods */}
                    {isYearOverYearDataLoaded && Object.keys(globalData?.yearOverYear || {}).length > 0 && (
                        <DateRange
                            label='YOY'
                            startDate={new Date(currentStartDate?.getFullYear() - 1, currentStartDate?.getMonth(), currentStartDate?.getDate())}
                            endDate={new Date(currentEndDate?.getFullYear() - 1, currentEndDate?.getMonth(), currentEndDate?.getDate())}
                        />
                    )}

                    {isPreviousDataLoaded && Object.keys(globalData?.previousPeriod || {}).length > 0 && (
                        <DateRange
                            label='Previous'
                            startDate={previousStartDate}
                            endDate={previousEndDate}
                        />
                    )}

                    {isCustomPeriodDataLoaded && Object.keys(globalData?.customPeriod || {}).length > 0 && (
                        <DateRange
                            label='Custom'
                            startDate={customStartDate}
                            endDate={customEndDate}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardDateRanges;
