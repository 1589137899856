import { useAuth, useTheme } from "../auth/AuthContext";

const Footer = () => {
    const theme = useTheme();
    const { logout } = useAuth();
    
    const handleLogout = (e) => {
        e.preventDefault();
        logout();
    };
    
    let company;
    
    if (theme && theme == 'mta') {
        company = 'mta360';
    } else {
        company = 'Range Marketing';
    }

    return (
        <div className='rr-dashboard-footer-wrapper'>
            <div className='rr-dashboard-footer'>
                <div className='text-sm'>Copyright &copy; 2024 <span className='font-bold'>{company}</span></div>
                <button onClick={handleLogout}>Log out</button>
            </div>
        </div>
    );
};

export default Footer;
