import { useCallback } from 'react';
import { formatDate } from '../../utils/dataProcessing';
import { additionalEndpoints } from '../../config/dataContextConfig';

export const useYearOverYear = ({
    accounts,
    currentStartDate,
    currentEndDate,
    fetchDataForType,
    setLoadingStates,
    setGlobalData,
    setIsYearOverYearDataLoaded,
    dataTypes
}) => {
    return useCallback(async () => {
        if (!accounts || !currentStartDate || !currentEndDate) return;

        try {
            setLoadingStates(prev => ({ ...prev, yearOverYear: true }));
            setIsYearOverYearDataLoaded(false);

            const yearOverYearPeriod = {
                startDate: formatDate(new Date(currentStartDate.getFullYear() - 1, currentStartDate.getMonth(), currentStartDate.getDate())),
                endDate: formatDate(new Date(currentEndDate.getFullYear() - 1, currentEndDate.getMonth(), currentEndDate.getDate()))
            };

            const newData = { yearOverYear: {} };
            
            // Prepare all promises in parallel
            const allPromises = dataTypes.flatMap(({ key }) => {
                if (!accounts?.[key] || key === 'reviews') return [];

                newData.yearOverYear[key] = {};

                return accounts[key].map(async (id) => {
                    try {
                        const apiKey = key === 'ga4-organic' ? 'ga4' : key;

                        // Base data fetch
                        const result = await fetchDataForType(
                            apiKey,
                            id,
                            null,
                            yearOverYearPeriod,
                            null,
                            '',
                            true,
                            false,
                            key === 'ga4-organic'
                        );

                        if (result.yearOverYear) {
                            newData.yearOverYear[key][id] = result.yearOverYear;

                            // GA4 specific endpoints
                            if ((key === 'ga4' || key === 'ga4-organic') && result.yearOverYear) {
                                const endpointPromises = additionalEndpoints.map(async (endpoint) => {
                                    const endpointResult = await fetchDataForType(
                                        apiKey,
                                        id,
                                        null,
                                        yearOverYearPeriod,
                                        null,
                                        endpoint,
                                        true,
                                        false,
                                        key === 'ga4-organic'
                                    );
                                    return { endpoint: endpoint.slice(1), ...endpointResult };
                                });

                                const endpointResults = await Promise.all(endpointPromises);
                                endpointResults.forEach(({ endpoint, yearOverYear }) => {
                                    if (yearOverYear) {
                                        newData.yearOverYear[key][id] = {
                                            ...newData.yearOverYear[key][id],
                                            [endpoint]: yearOverYear
                                        };
                                    }
                                });
                            }
                        }

                        // Ecommerce specific logic
                        if (key === 'ecommerce') {
                            const [overview, items, categories] = await Promise.all([
                                fetchDataForType('ga4', id, null, yearOverYearPeriod, null, '/ecommerce', true, false),
                                fetchDataForType('ga4', id, null, yearOverYearPeriod, null, '/ecommerce/items', true, false),
                                fetchDataForType('ga4', id, null, yearOverYearPeriod, null, '/ecommerce/categories', true, false)
                            ]);

                            newData.yearOverYear[key][id] = {
                                overview: overview?.yearOverYear,
                                items: items?.yearOverYear,
                                categories: categories?.yearOverYear
                            };
                        }
                    } catch (error) {
                        console.error(`Error fetching YOY data for ${key}:`, error);
                    }
                });
            });

            // Execute all promises in parallel
            await Promise.all(allPromises);

            setGlobalData(prev => ({
                ...prev,
                yearOverYear: newData.yearOverYear
            }));
            
            setIsYearOverYearDataLoaded(true);
        } finally {
            setLoadingStates(prev => ({ ...prev, yearOverYear: false }));
        }
    }, [accounts, currentStartDate, currentEndDate, fetchDataForType, setLoadingStates, setGlobalData, setIsYearOverYearDataLoaded, dataTypes]);
};
