import { useCallback } from 'react';
import { calculatePreviousPeriod } from '../../utils/dataContextUtils';
import { additionalEndpoints } from '../../config/dataContextConfig';

/**
 * Custom hook for loading previous period comparison data.
 * Handles fetching and processing data for the period immediately before the current period.
 * 
 * @param {Object} params - Hook parameters
 * @param {Object} params.accounts - Available accounts for each data type
 * @param {Date} params.currentStartDate - Current period start date
 * @param {Date} params.currentEndDate - Current period end date
 * @param {Function} params.fetchDataForType - Function to fetch data for a specific type
 * @param {Function} params.setLoadingStates - Function to update loading states
 * @param {Function} params.setGlobalData - Function to update global data
 * @param {Function} params.setIsPreviousDataLoaded - Function to update previous period loaded state
 * @param {Array} params.dataTypes - Array of data type configurations
 * @returns {Function} Function to trigger previous period data loading
 */
export const usePreviousPeriod = ({
    accounts,
    currentStartDate,
    currentEndDate,
    fetchDataForType,
    setLoadingStates,
    setGlobalData,
    setPreviousStartDate,
    setPreviousEndDate,  
    setIsPreviousDataLoaded,
    dataTypes
}) => {
    return useCallback(async () => {
        if (!accounts || !currentStartDate || !currentEndDate) return;

        try {
            setLoadingStates(prev => ({ ...prev, previousPeriod: true }));
            setIsPreviousDataLoaded(false);

            const previousPeriod = calculatePreviousPeriod(currentStartDate, currentEndDate);
            setPreviousStartDate(new Date(previousPeriod.startDate));
            setPreviousEndDate(new Date(previousPeriod.endDate));
            const newData = { previousPeriod: {} };

            // Prepare all promises in parallel
            const allPromises = dataTypes.flatMap(({ key }) => {
                if (!accounts?.[key] || key === 'reviews') return [];

                newData.previousPeriod[key] = {};

                return accounts[key].map(async (id) => {
                    try {
                        const apiKey = key === 'ga4-organic' ? 'ga4' : key;

                        // Base data fetch
                        const result = await fetchDataForType(
                            apiKey,
                            id,
                            null,
                            null,
                            previousPeriod,
                            '',
                            false,
                            true,
                            key === 'ga4-organic'
                        );

                        if (result.previousPeriod) {
                            newData.previousPeriod[key][id] = result.previousPeriod;

                            // GA4 specific endpoints
                            if ((key === 'ga4' || key === 'ga4-organic') && result.previousPeriod) {
                                const endpointPromises = additionalEndpoints.map(async (endpoint) => {
                                    const endpointResult = await fetchDataForType(
                                        apiKey,
                                        id,
                                        null,
                                        null,
                                        previousPeriod,
                                        endpoint,
                                        false,
                                        true,
                                        key === 'ga4-organic'
                                    );
                                    return { endpoint: endpoint.slice(1), ...endpointResult };
                                });

                                const endpointResults = await Promise.all(endpointPromises);
                                endpointResults.forEach(({ endpoint, previousPeriod: periodData }) => {
                                    if (periodData) {
                                        newData.previousPeriod[key][id] = {
                                            ...newData.previousPeriod[key][id],
                                            [endpoint]: periodData
                                        };
                                    }
                                });
                            }
                        }

                        // Ecommerce specific logic
                        if (key === 'ecommerce') {
                            const [overview, items, categories] = await Promise.all([
                                fetchDataForType('ga4', id, null, null, previousPeriod, '/ecommerce', false, true),
                                fetchDataForType('ga4', id, null, null, previousPeriod, '/ecommerce/items', false, true),
                                fetchDataForType('ga4', id, null, null, previousPeriod, '/ecommerce/categories', false, true)
                            ]);

                            newData.previousPeriod[key][id] = {
                                overview: overview?.previousPeriod,
                                items: items?.previousPeriod,
                                categories: categories?.previousPeriod
                            };
                        }
                    } catch (error) {
                        console.error(`Error fetching previous period data for ${key}:`, error);
                    }
                });
            });

            // Execute all promises in parallel
            await Promise.all(allPromises);

            setGlobalData(prev => ({
                ...prev,
                previousPeriod: newData.previousPeriod
            }));

            setIsPreviousDataLoaded(true);
        } finally {
            setLoadingStates(prev => ({ ...prev, previousPeriod: false }));
        }
    }, [accounts, currentStartDate, currentEndDate, fetchDataForType, setLoadingStates, setGlobalData, setIsPreviousDataLoaded, dataTypes]);
};
